<template>
	<div>
		<div class="tl-bar">
			<el-row type="flex" justify="space-between" align="middle">
				<el-col :span="2" class="L-text-align-center" onclick="javascript:history.back(-1);">
					<i class="el-icon-arrow-left L-font-bgtext"></i>
				</el-col>
				<el-col :span="20">
					<div class="L-text-align-center L-font-text">
						上机列表
					</div>
				</el-col>
				<el-col :span="2"></el-col>
			</el-row>
		</div>
		<div class="L-container-96 tl-margin-top">
			<el-input placeholder="请输入内容">
				<el-button slot="append" icon="el-icon-search"></el-button>
			</el-input>
		</div>

		<div class="L-container-96 L-margin-top-20">
			<div class="tl-title-row">
				<el-badge :value="48" class="item">
					<div class="L-font-text" style="padding-right: 10px;">神话网络会所</div>
				</el-badge>
			</div>

		</div>


		<div class="tl-list-container L-margin-top-10">
			<div class="tl-list-item" v-for="item in userList" :key="item.user_certificate_code" @click="toUserDetail(item.user_certificate_code)">
				<el-row :gutter="10" type="flex" justify="start" align="middle">
					<el-col :span="4" class="L-text-align-center">
						<i class="el-icon-monitor icon" style="font-size: 24px;"></i>
					</el-col>
					<el-col :span="3" class="L-text-align-center">
						<span class="L-font-text" style="color:#409EFF">{{item.computer_ip}}</span>
					</el-col>
					<el-col :span="17">
						<div>
							<span class="L-font-text">{{item.user_name}}</span>&nbsp;

						</div>
						<div>
							<span class="L-font-smtext L-font-color-777">密码登录</span>&nbsp;
							<span class="L-font-text L-font-color-777">{{item.user_certificate_code}}</span>
						</div>

					</el-col>
					<el-col :span="4" class="L-text-align-center">
						<i class="el-icon-arrow-right L-margin-top-10"></i>
					</el-col>
				</el-row>
			</div>
			
		</div>
	</div>

</template>

<script>
	export default{
		data(){
			return{
				userList:""
			}
		},
		methods:{
			toUserDetail(user_certificate_code){
				this.$router.push({
					path: "/policestation/userDetail",
					query:{user_certificate_code:user_certificate_code}
				})
				
			}
		},
		mounted(){
			this.$axios({
				method:"get",
				url:"/ctid/PhoneService/findZxComputerList",
				params:{
					room_code:this.$route.query.room_code,
				}
			}).then(res=>{
				this.userList = res.data.data;
				console.log(this.userList);
				
			})
		}
	}
</script>

<style>
	.tl-color {
		color: #409EFF
	}

	.L-container-96 {
		width: 96%;
		margin: 0px auto;
	}

	.L-container-90 {
		width: 90%;
		margin: 0px auto;
	}

	.L-container-80 {
		width: 80%;
		margin: 0px auto;
	}

	.L-flex-row {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}


	/* L-style */
	.L-row-space {
		width: 100%;
		height: 20px;
	}

	.L-row-nowrap {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.L-font-h1 {
		font-size: 40px;
	}

	.L-font-h2 {
		font-size: 35px;
	}

	.L-font-h3 {
		font-size: 30px;
	}

	.L-font-h4 {
		font-size: 25px;
	}

	.L-font-h5 {
		font-size: 20px;
	}

	.L-font-bgtext {
		font-size: 16px;
	}

	.L-font-text {
		font-size: 14px;
	}

	.L-font-smtext {
		font-size: 12px;
	}

	/* color */
	.L-font-color-gray {
		color: #95A5A6
	}

	.L-font-color-666 {
		color: #666;
	}

	.L-font-color-777 {
		color: #777;
	}

	.L-font-color-888 {
		color: #888;
	}

	.L-font-color-999 {
		color: #999;
	}

	.L-font-color-aaa {
		color: #aaa;
	}

	.L-font-color-bbb {
		color: #bbb;
	}

	.L-font-color-ccc {
		color: #ccc;
	}

	.L-font-color-ddd {
		color: #ddd;
	}

	.L-font-color-red {
		color: #F56C6C
	}

	.L-font-color-white {
		color: #fff;
	}

	/*  */
	.L-font-bold {
		font-weight: bold;
	}

	/* text-align */

	.L-text-align-center {
		text-align: center;
	}

	/*  */
	.L-row-90 {
		margin: 0 auto;
		width: 90%;
	}

	/* margin */
	.L-margin-top-10 {
		margin-top: 10px;
	}

	.L-margin-top-20 {
		margin-top: 20px;
	}

	.L-margin-top-30 {
		margin-top: 30px;
	}

	.L-margin-top-40 {
		margin-top: 40px;
	}

	.L-margin-top-50 {
		margin-top: 50px;
	}

	.L-margin-top-60 {
		margin-top: 60px;
	}

	.L-margin-top-70 {
		margin-top: 70px;
	}

	.L-margin-right-5 {
		margin-right: 5px;
	}

	.tl-margin-top {
		margin-top: 50px;
	}
	
	.tl-title-row {
		padding-left: 15px;
		height: 30px;
		line-height: 30px;
		border-left: 7px solid #409EFF;
	}
</style>
